<template>
  <Layout>
    <div class="card p-5 text-center">
      <h5 class="mt-5">Thank You!</h5>
      <p>Wrapping Job has been started</p>
      <div class="mb-5">
        <button
          @click="$router.push({ name: 'ScheduledJob', params: { id: 2 } })"
          style="width: 180px"
          class="primary-bseen-btn m-auto"
        >
          View ongoing jobs
        </button>
        <br />
        <button
          @click="$router.push({ name: 'ScheduledJob' })"
          style="width: 180px"
          class="secondary-bseen-btn m-auto"
        >
          Home
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
export default {
  components: {
    Layout,
  },
};
</script>

<style></style>
